import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';

import {sessionState} from 'session/session-state.js';
import {actionLogAPI} from 'api/action-log.js';
import {DateUtil} from 'utils/date-util.js';
import {ErrorUtil} from 'utils/error-util.js';
import {ActionLog} from 'models/action-log.js';

import {FarmSelect} from 'components/FarmSelect.js';
import {ReturnButton} from 'components/ReturnButton.js';
import {SimpleTable} from 'components/tables/SimpleTable.js';
import {ReadOnlyText} from 'components/ReadOnlyText.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
  readonlyText: GlobalCss.readonlyText,
  enclosedArea: GlobalCss.enclosedArea,
};

class ActionLogDetail extends React.Component {
  constructor(props) {
    super();

    this.state = {
      record: {
        notes: '',
      },
      details: [],
      detailColumns: [],
      canRollback: false,
      loaded: false,
      showProgress: false,
      showDialog: false,
    };

    this.farm = sessionState.currentFarm();
    this.id = props.location.state.id;
  }

  componentDidMount() {
    this.show();
  }

  show() {
    this.setState({showProgress: true});

    return actionLogAPI.show(this.farm.id(), this.id).then((res) => {
      const r = Object.assign({}, res.data);
      r.createdAtDisaply = DateUtil.toYYYYMMDDHHmm(r.createdAt);
      r.dataTypeLabel = ActionLog.dataTypeLabel(r.dataType);
      r.actionTypeLabel = ActionLog.actionTypeLabel(r.actionType);
      r.actionSummary = `${r.dataTypeLabel} - ${r.actionTypeLabel} (${r.quantity}件)`;

      const headers = r.details[0].inputValue.split('\t');
      headers.unshift('Excelの行番号');
      const columns = headers.map((header, index) => {
        return {
          id: String(index),
          label: header,
          style: {width: 100, minWidth: 100}
        };
      });

      const details = r.details.slice(1).map((row) => {
        // const lineNo = index + 2;
        const result = {'0': row.lineNo};
        const values = row.inputValue.split('\t');
        values.forEach((v, colIndex) => {
          result[String(colIndex + 1)] = v;
        });
        return result;
      });

      this.setState({
        record: r,
        details: details,
        detailColumns: columns,
        canRollback: !r.rollbacked,
        loaded: true,
        showProgress: false
      });
    });
  }

  returnAction() {
    this.props.history.push('/action_log');
  }

  rollback() {
    this.setState({showProgress: true});

    actionLogAPI.rollback(this.farm.id(), this.id).then((res) => {
      this.setState({showDialog: true, showProgress: false});
      setTimeout(() => {
        this.setState({showDialog: false});
        this.returnAction();
      }, 1000);
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({errorMessage: errorMessage});
      console.error(errorMessage);
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>操作履歴 - 詳細</h2>

        <ul className={classes.flexContainer}>
          <li>
            <ReturnButton returnAction={() => this.returnAction()} />
          </li>

          <li style={{marginLeft: '500px'}}>
            {this.state.canRollback ?
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.rollback()}
                disabled={!this.state.canRollback || this.state.showProgress}>
                巻き戻す
              </Button>
              : <span style={{fontWeight: 'bold', color: 'red'}}>巻き戻し済</span>
            }
          </li>
        </ul>

        <div style={{marginLeft: 40}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <FarmSelect changeAction={() => {}} isDisabled={true} />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">巻き戻しが完了しました</DialogTitle>
        </Dialog>

        <ul className={classes.flexContainer}>
          <li>
            <div style={{marginTop: 20, marginLeft: 50, width: 350}} className={classes.enclosedArea}>
              <ul className={classes.flexContainer}>
                <li style={{width: 80, fontWeight: 'bold'}}>
                  実行日時
                </li>
                <li>
                  {this.state.record.createdAtDisaply}
                </li>
              </ul>

              <ul className={classes.flexContainer}>
                <li style={{width: 80, fontWeight: 'bold'}}>
                  実行者名
                </li>
                <li>
                  {this.state.record.accountName}
                </li>
              </ul>

              <ul className={classes.flexContainer}>
                <li style={{width: 80, fontWeight: 'bold'}}>
                  操作内容
                </li>
                <li>
                  {this.state.record.actionSummary}
                </li>
              </ul>
            </div>
          </li>

          <li style={{marginTop: 20, marginLeft: 30}}>
            <ReadOnlyText
              label="備考"
              value={this.state.record.notes}
              multiline={true}
              style={{width: 500}}
            />
          </li>
        </ul>

        <SimpleTable
          records={this.state.details}
          columns={this.state.detailColumns}
          height={300}
        />
      </div>
    );
  }
}

ActionLogDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(ActionLogDetail));
export {forExport as ActionLogDetail};
